const show = () => console.log(msg, ...styleList);
const print = (str, style) => (msg += `%c${str}`) && styleList.push(style);
const println = (str, style) => print(`${str}\n`, style);

let msg = "";
let styleList = [];

print(" /\\", "color: #FFA726");
println(
  `_/\\
( o.o ) 
 > ^ <
`,
);

println("歡迎使用樂樂社區物管系統🎉\n", "font-weight: bold; font-size: 20px;");
println("社區官網:", "font-weight: bold;");
println("https://lelelink.com.tw/\n");

show();
